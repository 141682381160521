import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { AgreementDocumentsDisplay } from '../agreement/AgreementDocumentsDisplay';
import { MemberAgreementScrollText } from './MemberAgreementScrollText';
import { InformationSectionHeading } from '../account-credentials/AccountCredentialEntry';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { YlCheckBox } from '../../../infrastructure/forms/YlCheckBox';
import styles from './member-agreement-entry.module.scss';
import { useRootStore } from '../../root/RootStoreHooks';

export const MemberAgreementEntry = () => {
  const { t } = useTranslation();
  const rootStore= useRootStore();
  const agreementLabel = (rootStore.enrollmentStatus.countryIso== "HK" || rootStore.enrollmentStatus.countryIso== "MO")? t('hk_agreement_1'): t('AgreementCheckboxLabel'); 
  const memberAgreementEntry = useModuleStore('memberAgreementEntry');
  memberAgreementEntry.updateAgreementWithPreviouslyAccepted();
 
  return useObserver(() => {
    const form = memberAgreementEntry.reactForm;
    const { text, documents, scrollText } = memberAgreementEntry;
    return (
      <div>
        <InformationSectionHeading>{t('Agreements')}</InformationSectionHeading>
        {/* eslint-disable-next-line react/no-danger */}
        {text && <div className={`mb-3 ${styles.agreementScrollable}`} dangerouslySetInnerHTML={{ __html: text }} />}
        {documents && <AgreementDocumentsDisplay documents={documents} />}
        {scrollText && <MemberAgreementScrollText text={scrollText} />}
        <Form noValidate>
          <Form.Row>
            <YlCheckBox
              size={12}
              field={form.$.agreementAccepted}
              label={agreementLabel}
              id="AgreementCheckboxLabel"
            />
          </Form.Row>
        </Form>
        { (rootStore.enrollmentStatus.countryIso== "HK" || rootStore.enrollmentStatus.countryIso == "MO") && (    
       <div>    
        <ol>
          <li>{t('hk_agreement_2')}</li>
          <li>{t('hk_agreement_3')}</li>
          <li>{t('hk_agreement_4')}</li>
          <li>{t('hk_agreement_6')}</li>
          <li>{t('hk_agreement_7')}</li>
          <li>{t('hk_agreement_8')}</li>
          <li>{t('hk_agreement_9')}</li>
        </ol>
        </div>
  )} 
      </div>
    );
  });
};
