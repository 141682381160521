import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Col, Form, Modal } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { BillingEntryStore } from '../../model/BillingEntryStore';
import { PaypalButton } from '../paypal/PayPalButton';
import { VirtualWalletPaypalButton } from '../virtual-wallet/VirtualWalletPaypalButton';
import { PaymentMethodRadioButtons } from './PaymentMethodRadioButtons';
import { PaymentMethodEditor } from './PaymentMethodEditor';
import { BillingInformationDisplay } from './BillingInformationDisplay';
import { useModuleDisplayMode } from '../../../enrollment-process/steps-management/components/step-layouts/accordion/ModuleDisplayMode';
import { EnrollmentPaymentType } from '../../../../external/shared/api/EnrollmentClient.generated';
import { useRootStore } from '../../../root/RootStoreHooks';

interface PaymentMethodPickerProps {
  billingStore: BillingEntryStore;
}

export const PaymentMethodPicker = observer<PaymentMethodPickerProps>(({ billingStore }) => {
  const { t } = useTranslation();
  const displayMode = useModuleDisplayMode();
  const rootStore = useRootStore();
  if (displayMode) {
    return <BillingInformationDisplay store={billingStore} />;
  }
  return (
    <>
      <Form.Group>
        {billingStore.showRadioSelections ? (
          <PaymentMethodRadioButtons
            methods={billingStore.pickablePayments}
            selected={billingStore.selectedPaymentType}
            onChange={method => billingStore.switchSelectedPaymentType(method)}
          />
        ) : (
          <>
          {(rootStore.enrollmentStatus.countryIso=='MO') &&(<h6>{t('BillingInformationHelp')}</h6>)}
          <PaymentMethodEditor method={billingStore.availablePaymentMethods[0]} />
          </>
        )}
      </Form.Group>
      {billingStore.shouldShowPayPalButton && (
        <Form.Group>
          <Form.Row>
            <Col xs>
              <PaypalButton
                productionMode={billingStore.payPalEnvironment === 'production'}
                memberCountry={rootStore.enrollmentStatus.countryIso}
                onSelected={() => billingStore.switchSelectedPaymentType(EnrollmentPaymentType.PayPal)}
              />
            </Col>
          </Form.Row>
        </Form.Group>
      )}
      {billingStore.shouldShowVirtualWalletPayPalButton && (
        <Form.Group>
          <Form.Row>
            <Col xs>
              <VirtualWalletPaypalButton />
            </Col>
          </Form.Row>
        </Form.Group>
      )}
      <Modal show={billingStore.showModal} onHide={() => !billingStore.showErrorModal(false)} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{t('PaymentErrorTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>An Unknown Error Occured</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => !billingStore.showErrorModal(false)}>
            {t('Close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});
