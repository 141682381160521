import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal } from 'react-bootstrap';

import { useRootStore } from '../../../../../root/RootStoreHooks';
import { Stripe3dsStore } from './Stripe3dsStore';

function addWindowMessageListener(store: Stripe3dsStore) {
  function postMessage(event: any) {
    if (event.data === '3DS-authentication-complete') {
      store.threeDsCompleteDeferred!();
    }
  }
  window.addEventListener('message', postMessage, false);
  return () => window.removeEventListener('message', postMessage, false);
}

export const Stripe3ds = observer(() => {
  const rootStore = useRootStore();
  const { threeDSecureEntry: { stripe3ds } = {} } = rootStore.moduleStores;

  useEffect(() => {
    if (!stripe3ds?.isEnabled) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }
    const removeListener = addWindowMessageListener(stripe3ds);
    stripe3ds.initAuth();
    return () => removeListener();
  }, [stripe3ds, stripe3ds?.isEnabled]);

  return stripe3ds?.isEnabled && stripe3ds?.acsUrl ? (
    <Modal show contentClassName="h-100" backdrop={false} animation={false} size="lg" centered scrollable>
      <iframe id="stripe-3ds-iframe" title="Stripe 3ds" src={stripe3ds.acsUrl} className="flex-grow-1" />
    </Modal>
  ) : null;
});
