import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { PaymentMethodPicker } from './payment-choices/PaymentMethodPicker';
import { PaymentInstallmentModal } from './installment/PaymentInstallmentModal';
import { useVariant } from '../../enrollment-process/module-management/ModuleVariants';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { Braintree3ds } from './credit-card/three-d-secure/braintree-3ds/Braintree3ds';
import { Nuvei3ds } from './credit-card/three-d-secure/nuvei-3ds/Nuvei3ds';
import { StepLayout } from '../../../external/shared/api/EnrollmentClient.generated';

export const BillingEntry = observer(() => {
  const { t } = useTranslation();
  const variant = useVariant();
  const showHeading = variant === StepLayout.Standard;
  const billing = useModuleStore('billingEntry');

  return (
    <>
      {showHeading && <h3 className="text-primary">{t('BillingInformation')}</h3>}
      <PaymentMethodPicker billingStore={billing} />
      <PaymentInstallmentModal billingStore={billing} />
      <Braintree3ds />
      <Nuvei3ds />
    </>
  );
});
