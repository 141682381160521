import React from 'react';
import { useObserver } from 'mobx-react-lite';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { PromoVoucherModal } from './PromoVoucherModal';
import { YlInput } from '../../../infrastructure/forms/YlInput';
import styles from '../order-review.module.scss';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';

export const PromoVoucherEntry = () => {
  const { t } = useTranslation();
  const promoVoucherEntry = useModuleStore('promoVoucherEntry');

  return useObserver(() => {
    const form = promoVoucherEntry.reactForm;
    return (
      <div className="mt-3 p-3 bg-light">
        <Card.Title className={styles.titleBorder}>{t('VoucherCode')}</Card.Title>
        <Form noValidate onSubmit={(e: React.FormEvent) => e.preventDefault()}>
          <Form.Row>
            <YlInput size={8} label="" required field={form.$.voucherCode} id="voucherCode"  customAttributes={{ 'data-testid': 'voucher-Code' }} />
            <Button variant="link" onClick={() => promoVoucherEntry.validateAndApplyPromoVoucherCode()}>
              {t('ApplyVoucher')} &#187;
            </Button>
          </Form.Row>
        </Form>
        {promoVoucherEntry.showModal && (
          <PromoVoucherModal isOpen={promoVoucherEntry.showModal} onClose={() => promoVoucherEntry.hideModal()} />
        )}
      </div>
    );
  });
};
