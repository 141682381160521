import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal } from 'react-bootstrap';

import { useRootStore } from '../../../../../root/RootStoreHooks';

export const DirectContentComponent: FC = props => <>{props.children}</>;

export const Chase3ds = observer(() => {
  const store = useRootStore().moduleStores.threeDSecureEntry?.chase3ds;

  useEffect(() => {
    if (store?.isEnabled) {
      store.initAuth();
    }
  }, [store, store?.isEnabled]);

  return (
    <>
      {store?.isEnabled && (
        <Modal show={store.display3dsDialog} dialogAs={DirectContentComponent} backdrop={false} animation={false}>
          {store.chase3ds.component}
        </Modal>
      )}
    </>
  );
});
