import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Card, Col, Form, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { States } from '../../../reference/components/States';
import { Suburbs } from '../../../reference/components/Suburbs';
import { Cities } from '../../../reference/components/Cities';
import { CustomSuburb } from '../../../reference/components/CustomSuburb';
import { ComponentField } from '../../../infrastructure/dynamic-forms/ComponentField';
import { Countries } from '../../../reference/components/Countries';
import { YlInput } from '../../../infrastructure/forms/YlInput';
import { AddressEntryFormData } from '../main-address/AddressEntryFormData';
import { AddressRow } from '../main-address/module-form/AddressRow';
import { AddressModal } from '../validator/AddressModal';
import { MarketChangeModal } from '../../locale-management/warning-modal/MarketChangeModal';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { useRootStore } from '../../root/RootStoreHooks';
import { useModuleDisplayMode } from '../../enrollment-process/steps-management/components/step-layouts/accordion/ModuleDisplayMode';
import { AddressDisplay } from '../AddressDisplay';
import { PostalCodes } from '../../../reference/components/PostalCodes';

export interface AddressDisplayProps {
  address: AddressEntryFormData;
}

export const ShippingAddressDisplay = observer<AddressDisplayProps>(({ address }) => {
  const { t } = useTranslation();
  return (
    <>
      <p>
        <span>{t('Country')}: </span>
        <span className="text-primary"> {address.country}</span>
      </p>
      <p>
        <span>{t('Address')}: </span>
          {address.country === 'JP' && 
            <span className="text-primary">
              {address.postalCode && <span>{address.postalCode}</span>}
              {address.stateProv && <span>, {address.stateProv}</span>}
              {address.city && <span> {address.city}</span>}
              {address.street1}
              {address.street2 && <span> {address.street2}</span>}
            </span>
          }
          {address.country !== 'JP' && 
            <span className="text-primary">
              {address.street1}
              {address.street2 && <span> {address.street2}</span>}
              {address.street3 && <span> {address.street3}</span>}
              {address.city && <span> {address.city}</span>}
              {address.stateProv && <span> {address.stateProv}</span>}
              {address.locationId && <span>, {address.locationId}</span>}
              {address.postalCode && <span>, {address.postalCode}</span>}
              {address.phoneNumber && <span>, {address.phoneNumber}</span>}
            </span>
          }
      </p>
      {address.isPickupPoint && (
        <p>
          <span>{t('PickupPoint_Recipient')}: </span>
          <span className="text-primary">
            {address.recipient && <span>{address.recipient}</span>}
            {address.phoneNumber && <span>, {address.phoneNumber}</span>}
          </span>
        </p>
      )}
    </>
  );
});

export const RecipientInfoDisplay = observer<AddressDisplayProps>(({ address }) => {
  const { t } = useTranslation();
  return (
    <Card bg="light" border="light" style={{ width: '21rem' }}>
      <Card.Body>
        <Card.Title>{t('PickupPoint_Recipient')}</Card.Title>
        <div>
          {address.recipient && <div>{address.recipient}</div>}
          {address.phoneNumber && <div>{address.phoneNumber}</div>}
        </div>
      </Card.Body>
    </Card>
  );
});

export const ShippingAddressEntry = observer(() => {
  const { t } = useTranslation();
  const rootStore = useRootStore();
  const addressEntry = useModuleStore('shippingAddressEntry');
  const moduleDisplayMode = useModuleDisplayMode();

  function getCustomerAttributes(field: ComponentField) {
    const attr = {} as any;
    if (field.maxLength) {
      attr.maxLength = field.maxLength;
    }

    if (field.minLength) {
      attr.minLength = field.minLength;
    }

    return attr;
  }

  if (!addressEntry) {
    return <Spinner animation="border" variant="secondary" />;
  }

  if (moduleDisplayMode) {
    return <ShippingAddressDisplay address={addressEntry.addressForDisplay} />;
  }

  return (
    <Form>
      {addressEntry.displayingWillCall && (
        <Form.Group>
          <AddressDisplay address={addressEntry.willCallAddress!} />
        </Form.Group>
          )}
      {addressEntry.displayingAusPost && (
        <Form.Group>
          <AddressDisplay address={addressEntry.ausPostAddress!} />
        </Form.Group>
          )}
      {addressEntry.displayingShipToShop && (
        <Form.Group>
          <AddressDisplay address={addressEntry.shipToShopAddress!} />
        </Form.Group>
          )}
      {addressEntry.displayingPickupPoint && (
        <>
          <Form.Row>
            <Form.Group as={Col} md={6}>
              <AddressDisplay address={addressEntry.pickupPointAddress!} />
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <RecipientInfoDisplay address={addressEntry.pickupPointAddress!} />
            </Form.Group>
          </Form.Row>
          <Form.Group>
            <Button variant="outline-primary" size="sm" onClick={() => addressEntry.changePickupPoint()}>
              {t('PickupPoint_Change')}
            </Button>
          </Form.Group>
        </>
      )}
      {!addressEntry.showAusPostWidget && !addressEntry.displayingWillCall && !addressEntry.displayingPickupPoint && !addressEntry.displayingShipToShop && (
        <>
          {addressEntry.askIfSameAsPrimaryAddress && (
            <>
              <Form.Group>
                <Form.Check
                  id="sameAsPrimary"
                  custom
                  label={t('ShippingAddressSameAsPrimary')}
                  type="checkbox"
                  checked={addressEntry.sameAsPrimaryAddress}
                  onChange={() => addressEntry.toggleSameAsPrimaryAddress()}
                />
              </Form.Group>
              {addressEntry.sameAsPrimaryAddress && (
                <Form.Group>
                  <AddressDisplay address={addressEntry.primaryAddress!} />
                </Form.Group>
              )}
            </>
          )}

            {!addressEntry.showAusPostWidget && !addressEntry.sameAsPrimaryAddress &&
              addressEntry.form.rows.map((row: AddressRow, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <Form.Row key={index}>
                  {row.formFields.map((field: ComponentField) => (
                    <React.Fragment key={field.name}>
                      {field.name === 'postalCode' && !field.disabled && (
                        <>
                          <PostalCodes
                            required={field.required}
                            size={field.size}
                            field={addressEntry.reactForm.$[field.name]}
                            customAttributes={getCustomerAttributes(field)}
                            label={field.labelKey}
                            id={field.name}
                            disabled={field.disabled}
                            additionalError=''
                            fieldType={field.fieldType}
                            countryIso={addressEntry.reactForm.$.country.value}
                            stateProv={addressEntry.reactForm.$?.stateProv?.value}
                            city={addressEntry.reactForm.$.city.value}
                            onChange={() => {
                              addressEntry.applyFormat(field);
                              addressEntry.tryAutoFillJpData(field);
                            }}
                          />
                          {addressEntry.jpZipNotFound && <p>{t('ZipCodeNotFoundNotification')}</p>}
                        </>
                      )}

                      {field.fieldType === 'Input' && field.name !== 'postalCode'
                        && field.name !== 'city' && field.name !== 'phoneNumber'
                        && (field.name !== 'street2' || rootStore.enrollmentStatus.countryIso !== 'CA') && (
                      <YlInput
                        required={field.required}
                        size={field.size}
                        field={addressEntry.reactForm.$[field.name]}
                        customAttributes={getCustomerAttributes(field)}
                        label={field.labelKey}
                        id={field.name}
                        disabled={field.disabled}
                        onChange={() => addressEntry.applyFormat(field)}
                        onBlur={addressEntry.onInputBlurred}
                      />
                    )}

                    {field.fieldType === 'Select' && field.name === 'country' && (
                      <Countries
                        size={field.size}
                        required={field.required}
                        field={addressEntry.reactForm.$[field.name]}
                        form={addressEntry.reactForm}
                        disabled={false}
                        label={field.labelKey}
                        onChange={country => addressEntry.onCountryChange(country)}
                      />
                    )}

                    {field.fieldType === 'Select' && field.name === 'stateProv' && (
                      <States
                        required={field.required}
                        countryIso={addressEntry.reactForm.$.country.value}
                        field={addressEntry.reactForm.$[field.name]}
                        size={field.size}
                        label={field.labelKey}
                        onChange={addressEntry.onStateChange}
                      />
                    )}

                      {field.name === 'city' && (
                        <Cities
                          id={field.name}
                          fieldType={field.fieldType}
                          countryIso={addressEntry.reactForm.$.country.value}
                          stateProv={addressEntry.reactForm.$?.stateProv?.value}
                          required={field.required}
                          name={field.name}
                          field={addressEntry.reactForm.$[field.name]}
                          size={field.size}
                          label={field.labelKey}
                          disabled={field.disabled}
                          onChange={city => city}
                        />
                      )}

                      {field.fieldType === 'Select' && field.name === 'suburb' && (
                        <Suburbs
                          required={field.required}
                          stateAbbreviation={addressEntry.reactForm.$?.stateProv?.value}
                          noSuburb={addressEntry.reactForm.$.noSuburb.value}
                          field={addressEntry.reactForm.$[field.name]}
                          size={field.size}
                          label={field.labelKey}
                          onChange={addressEntry.UpdateSuburb}
                        />
                      )}

                    {field.fieldType === 'Check' && field.name === 'noSuburb' && (
                      <CustomSuburb
                        field={addressEntry.reactForm.$[field.name]}
                        label={field.labelKey}
                        id={field.name}
                        onChange={addressEntry.UpdateNoSuburb}
                      />
                    )}
                 {(rootStore.enrollmentStatus.countryIso=='HK' || rootStore.enrollmentStatus.countryIso=='MO') && field.name == 'phoneNumber'&& (
                      <YlInput
                        required={field.required}
                        size={field.size}
                        field={addressEntry.reactForm.$[field.name]}
                        customAttributes={getCustomerAttributes(field)}
                        label={field.labelKey}
                        id={field.name}
                        disabled={field.disabled}
                        onChange={() => addressEntry.applyFormat(field)}
                        onBlur={addressEntry.onInputBlurred}
                      />
                )}
                  </React.Fragment>
                ))}
              </Form.Row>
              
            ))}
          {addressEntry.triggerCountryChangeAlert && (
            <MarketChangeModal
              showWarning={addressEntry.showWarning}
              hide={addressEntry.cancelCountryChange}
              agreeAndContinue={rootStore.cancelEnrollment}
              country={addressEntry.shippingAddress.country}
              language={undefined}
            />
          )}

          <AddressModal store={addressEntry.addressValidator} />
        </>
      )}
    </Form>
  );
});
