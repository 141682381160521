import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ERAgreementStore } from './ERAgreementStore';
import { useRootStore } from '../../root/RootStoreHooks';
import { YlCheckBox } from '../../../infrastructure/forms/YlCheckBox';
import styles from './er-agreement-entry.module.scss';
import Form from 'react-bootstrap/Form';

interface Props {
  store: ERAgreementStore;
}
export const ERAgreementEntry: FC<Props> = ({ store }) => {
  const root = useRootStore();
  const { t } = useTranslation();
  const erKitStore = root.moduleStores.erKitSelection ?? root.moduleStores.socialLinkErKitSelection!;
  const scrollThroughAgreement = t('scrollThroughAgreement'); //'Scroll through and read the Member Agreement below and verify that you accept these terms by checking the box at the bottom of the agreement.';
  const EssentialRewardsIHaveRead = t('EssentialRewardsIHaveRead');
  const memberAgreement = t('memberAgreement'); //'Member Agreement (Required Field)';
  const EssentialRewardsHeading = t('EssentialRewardsHeading'); //  'This is an agreement through which Independent Distributors of Young Living Essential Oils may participate in the Essential Rewards program';
  const EssentialRewardsBenefitsHeading = t('EssentialRewardsBenefitsHeading'); //'Participants Enjoy The Following Benefits:';
  const EssentialRewardsCredits = t('EssentialRewardsCredits'); //'Essential Rewards credits: Credits may be earned on each Essential Reward order placed. These credits may be redeemed for free products. Credits are earned with each consecutive month of participation.';
  const EssentialRewardsAdditional = t('EssentialRewardsAdditional'); //'Additional Benefits: Essential Rewards members will also be eligible to participate in “members only” specials.';
  const EssentialRewardsBenefits1 = t('EssentialRewardsBenefits1'); //'First six consecutive months of participation: 10% of Essential Rewards order.';
  const EssentialRewardsBenefits2 = t('EssentialRewardsBenefits2'); //'Second six consecutive months of participation: 15% of Essential Rewards order.';
  const EssentialRewardsBenefits3 = t('EssentialRewardsBenefits3'); //'13th month and beyond: 20% of Essential Rewards order.';
  const EssentialRewardsBenefits4 = t('EssentialRewardsBenefits4'); //'Credits are redeemable after two consecutive months of participation and are valid toward full PV products only (excluding Essential Reward Packs). The credits are generally equal to one PV and may not be used towards shipping and taxes. Products purchased with ER credits have no Personal or Organizational Volume. Credits may be redeemed by contacting Member Services Department at +65 6391 0170.';
  const Continue = t('Continue'); //'Continue';

  return useObserver(() => {
    const form = store.reactForm;
    return (
      <Modal
        show={store.showmodel}
        backdrop="static"
        size="lg"
        centered
        onHide={() => store.hideErAgreementModal(erKitStore)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('EssentialRewardsAgreement_Title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{EssentialRewardsHeading}</p>
          <strong>{EssentialRewardsBenefitsHeading}</strong>
          <p>{EssentialRewardsCredits}</p>
          <ul>
            <li>{EssentialRewardsBenefits1}</li>
            <li>{EssentialRewardsBenefits2}</li>
            <li>{EssentialRewardsBenefits3}</li>
            <li>{EssentialRewardsBenefits4}</li>
          </ul>
          <p>{EssentialRewardsAdditional}</p>
          {erKitStore.text && (<div className="terms-box"><p className="content" dangerouslySetInnerHTML={{ __html: erKitStore.text }}></p></div>)
          } 
          <div>
            {erKitStore.scrollText && (
              <div>
                <h5>{memberAgreement}</h5>

                <p>{scrollThroughAgreement}</p>
                <div className={styles.scrollTextContainer}>
                  <p className="content" dangerouslySetInnerHTML={{ __html: erKitStore.scrollText }}></p>

                  <div className="mt-4">
                    <Form noValidate>
                      <Form.Row>
                        <label>
                          <YlCheckBox
                            size={12}
                            field={form.$.agreementAccepted}
                            label={EssentialRewardsIHaveRead}
                            id="ErAgreementCheckboxWithScroll"
                          />
                        </label>
                      </Form.Row>
                    </Form>
                  </div>
                </div>
                <div />
              </div>
            )}

            {!erKitStore.scrollText && (
              <div className="mt-4">
                <Form noValidate>
                  <Form.Row>
                    <label>
                      <YlCheckBox
                        size={12}
                        field={form.$.agreementAccepted}
                        label={EssentialRewardsIHaveRead}
                        id="ErAgreementCheckboxWithOutScroll"
                      />
                    </label>
                  </Form.Row>
                </Form>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-footer">
            <Button
              type="submit"
              variant="primary"
              disabled={!form.$.agreementAccepted.value}
              onClick={() => {
                erKitStore.updateErAgreementAcceptedValue(form.$.agreementAccepted.value, false);
              }}
            >
              {Continue}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  });
};
