import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/Card';
import { AddressEntryFormData } from './main-address/AddressEntryFormData';

export interface AddressDisplayProps {
  address: AddressEntryFormData;
  hideTitle?: boolean;
  oneLiner?: boolean;
}

export const AddressDisplay: FC<AddressDisplayProps> = ({ address, hideTitle, oneLiner }) => {
  const { t } = useTranslation();
  return useObserver(() => {
    if (oneLiner) {
      return (
        <div>
          <span>{t('YourAddress')}:</span>
          <span className="text-primary"> {address.asOneLiner}</span>
        </div>
      );
    }

    return (
      <>
      <Card bg="light" border="light" style={{ width: '21rem' }}>
        <Card.Body>
          {!hideTitle && <Card.Title>{t('ShippingAddress')}</Card.Title>}
          {address.country === 'JP' && 
            <div data-testid="address-display">
              <div>{address.country}</div>
              {address.postalCode && <span>{address.postalCode}</span>}
              {address.stateProv && <span>, {address.stateProv}</span>}
              {address.city && <span> {address.city}</span>}
              <div>{address.street1}</div>
              {address.street2 && <div>{address.street2}</div>}
            </div>
          }
          {address.country !== 'JP' && 
            <div data-testid="address-display">
              <div>{address.street1}</div>
              {address.street2 && <div>{address.street2}</div>}
              {address.street3 && <div>{address.street3}</div>}
              {address.city && <span> {address.city}</span>}
              {address.stateProv && <span> {address.stateProv}</span>}
              {address.postalCode && <span>, {address.postalCode}</span>}
              {address.phoneNumber && <span>, {address.phoneNumber}</span>}
              {address.locationId && <span>, {address.locationId}</span>}
              <div> {address.country}</div>
            </div>
          }
        </Card.Body>
      </Card>
      {address.country=="HK" &&(<p>{t('AdditionalShippingInformation')}</p>)}
      </>
    );
  });
};
