import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';
import { Sepa } from '../../model/Sepa';

interface DisplayItemProps {
  label: string;
  value?: string;
}
const DisplayItem: FC<DisplayItemProps> = ({ label, value }) => {
  const { t } = useTranslation();
  return (
    <p>
      <span>{t(label)}:</span>
      <span className="text-primary"> {value}</span>
    </p>
  );
};

interface Props {
  sepa: Sepa;
}

export const SepaDisplay: FC<Props> = ({ sepa }) => {
  return useObserver(() => (
    <>
      <DisplayItem label="FirstName" value={sepa.firstNameOnAccount} />
      <DisplayItem label="LastName" value={sepa.lastNameOnAccount} />
      <DisplayItem label="Iban" value={sepa.iban} />
    </>
  ));
};
