import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import Form from 'react-bootstrap/Form';
import { YlDropdown } from '../../../infrastructure/forms/YlDropdown';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { useModuleDisplayMode } from '../../enrollment-process/steps-management/components/step-layouts/accordion/ModuleDisplayMode';

import { ShippingAlert } from './SupportingComponents/ShippingAlert';
import { getShippingMethodLabel } from './SupportingComponents/ShippingMethodOption';
import { ShippingMethodSelectionErrorModal } from './ShippingMethodSelectionErrorModal';
import { ShippingMethodWillCallDisplay } from './SupportingComponents/ShippingMethodWillCallDisplay';

export const ShippingMethodSelection: FC = observer(() => {
  const { t } = useTranslation();
  const shippingSelection = useModuleStore('shippingMethodSelection');
  const displayView = useModuleDisplayMode();

  const form = shippingSelection.reactForm;

  useEffect(() => {
    if (shippingSelection.selectedShippingMethod?.isAusPost){
      shippingSelection.changeShippingMethod(shippingSelection.selectedShippingMethod.id);
    }
  }, []);

  if (displayView && shippingSelection.selectedShippingMethod) {
    return (
      <p>
        <span>{t('ShippingMethod')}: </span>
        <span className="text-primary">{shippingSelection.selectedShippingMethod.name}</span>
      </p>
    );
  }
  return (
    <Form>
      {shippingSelection.showWarning && (
        <ShippingAlert
          storeChangeWarning={shippingSelection.storeChangeWarning}
          hasNoShippingMethods={shippingSelection.noShippingMethods}
          showDangerousGoodsWarning={shippingSelection.showDangerousGoodsWarning}
          showCbdWarning={shippingSelection.showCbdGoodsWarning}
          showRestrictedsGoodsWarning={shippingSelection.showRestrictedsGoodsWarning}
          itemNamesRestrictedByLocation={shippingSelection.itemNamesRestrictedByLocation}
        />
      )}
      <h3 className="text-primary">{t('ShippingMethod')}</h3>
      <p>{t('ShippingInformationHelp')}</p>
      <Form.Row>
        <YlDropdown
          loading={shippingSelection.shippingMethodsLoading}
          options={shippingSelection.shippingMethods}
          valueFn={shipping => shipping.id}
          labelFn={shipping => getShippingMethodLabel(shipping, t)}
          onChange={value => shippingSelection.changeShippingMethod(Number(value))}
          onFocus={() => shippingSelection.reQueryShippingMethodsIfNecessary()}
          size={8}
          id="shippingMethod"
          required
          field={form.$.shippingMethod}
          label="PickAMethod"
        />
      </Form.Row>
      {(shippingSelection.selectedShippingMethod?.id == 515 || shippingSelection.selectedShippingMethod?.id == 517) && (
      <>
        <p style={{ color:'red'}}>{t('Ship2ShopWarning_Line1')}</p>
        <p style={{ color:'red'}}>{t('Ship2ShopWarning_Line2')}</p>
        <p style={{ color:'red'}} dangerouslySetInnerHTML={{ __html: t('Ship2ShopWarning_Line3')}}/>
        <a  href={t('Ship2ShopDeliveryPolicyUrl')} target="_blank" rel="noopener noreferrer">{t('Ship2ShopWarning_Line4')}</a>
      </> 
    )} 
      {shippingSelection.selectedShippingMethod && shippingSelection.selectedShippingMethod.deliveryTimes.length > 0 && (
        <Form.Row>
          <YlDropdown
            options={shippingSelection.selectedShippingMethod.deliveryTimes}
            valueFn={shipping => shipping.id}
            labelFn={shipping => shipping.name}
            label="DeliveryTime"
            onChange={value => shippingSelection.changeDeliveryTime(Number(value))}
            size={4}
            id="deliveryTime"
            field={form.$.deliveryTime}
          />
        </Form.Row>
      )}
      
      {shippingSelection.selectedShippingMethod?.isAusPost && (
        <div id="auspost-widget"></div>
      )}
      {shippingSelection.selectedShippingMethod && shippingSelection.selectedShippingMethod.isWillCall && (
        <ShippingMethodWillCallDisplay />
      )}
      <ShippingMethodSelectionErrorModal />
    </Form>
  );
});
